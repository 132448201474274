import firebase from 'firebase/compat/app';
import store from './store';
import { ValuationStatus } from './store/models/valuation';
import { clientNameWrapper } from './helpers/utils';

export type CollectionKeys =
  | 'assets'
  | 'valuations'
  | 'projects'
  | 'admin'
  | 'settings'
  | 'idin'
  | 'investor'
  | 'idinIssuers'
  | 'bankAccountChanges'
  | 'pay'
  | 'investments'
  | 'earnings'
  | 'costs'
  | 'repayments'
  | 'selectedAccount'
  | 'payments'
  | 'bids'
  | 'identificationSettings'
  | 'dataChangeRequests'
  | 'identificationRequest';

export const collections: Record<CollectionKeys, Promise<unknown> | null> = {
  assets: null,
  valuations: null,
  projects: null,
  admin: null,
  settings: null,
  idin: null,
  investor: null,
  idinIssuers: null,
  bankAccountChanges: null,
  pay: null,
  investments: null,
  earnings: null,
  costs: null,
  repayments: null,
  selectedAccount: null,
  payments: null,
  dataChangeRequests: null,
  bids: null,
  identificationSettings: null,
  identificationRequest: null,
};

export const initializeNonUserCollections = (firestoreInstance: firebase.firestore.Firestore): void => {
  collections.assets = store.dispatch('bindRef', {
    name: 'assets',
    ref: clientNameWrapper(
      firestoreInstance.collection('assets').where('published', '==', true).where('deleted', '==', false),
    ).orderBy('startDateTime', 'desc'),
  });
  collections.valuations = store.dispatch('bindRef', {
    name: 'valuations',
    ref: clientNameWrapper(
      firestoreInstance
        .collectionGroup('valuations')
        .where('deleted', '==', false)
        .where('status', '==', ValuationStatus.Applied),
    ).orderBy('applyDateTime', 'desc'),
  });
  collections.projects = store.dispatch('bindRef', {
    name: 'projects',
    ref: clientNameWrapper(firestoreInstance.collectionGroup('projects').where('deleted', '==', false)).orderBy(
      'createdDateTime',
      'desc',
    ),
  });
  collections.admin = store.dispatch('bindRef', {
    name: 'admin',
    ref: firestoreInstance.collection('settings').doc('admin'),
  });
  collections.settings = store.dispatch('bindRef', {
    name: 'settings',
    ref: firestoreInstance.collection('settings').doc('generic'),
  });
  collections.identificationSettings = store.dispatch('bindRef', {
    name: 'identificationSettings',
    ref: firestoreInstance.collection('settings').doc('identification'),
  });
};

export const initializeUserCollections = (
  firestoreInstance: firebase.firestore.Firestore,
  uid?: string,
  removedAssets?: firebase.firestore.DocumentReference[],
): void => {
  if (uid) {
    const investorRef = firestoreInstance.collection('investors').doc(uid);
    collections.investor = store.dispatch('bindRef', {
      name: 'user',
      ref: investorRef,
    });
    collections.selectedAccount = store.dispatch('bindRef', {
      name: 'selectedInvestor',
      ref: investorRef,
    });
    collections.earnings = store.dispatch('bindRef', {
      name: 'earnings',
      ref: firestoreInstance
        .collectionGroup('earnings')
        .where('deleted', '==', false)
        .where('investor', '==', investorRef)
        .orderBy('earningDateTime', 'desc'),
    });
    collections.costs = store.dispatch('bindRef', {
      name: 'costs',
      ref: firestoreInstance
        .collectionGroup('costs')
        .where('deleted', '==', false)
        .where('investor', '==', investorRef)
        .orderBy('costDateTime', 'desc'),
    });
    collections.repayments = store.dispatch('bindRef', {
      name: 'repayments',
      ref: firestoreInstance
        .collectionGroup('repayments')
        .where('deleted', '==', false)
        .where('investor', '==', investorRef)
        .orderBy('repaymentDateTime', 'desc'),
      options: {
        maxRefDepth: 0,
      },
    });
    collections.idin = store.dispatch('bindRef', {
      name: 'idin',
      ref: firestoreInstance.collection('idin').doc(uid),
    });
    collections.idinIssuers = store.dispatch('bindRef', {
      name: 'idinIssuers',
      ref: firestoreInstance.collection('idinIssuers').doc('issuers'),
    });
    collections.identificationRequest = store.dispatch('bindRef', {
      name: 'identificationRequest',
      ref: firestoreInstance.collection('identificationRequests').doc(uid),
    });
    collections.bankAccountChanges = store.dispatch('bindRef', {
      name: 'bankAccountChanges',
      ref: firestoreInstance
        .collection('bankAccountChanges')
        .where('investor', '==', investorRef)
        .orderBy('createdDateTime', 'desc'),
    });
    collections.dataChangeRequests = store.dispatch('bindRef', {
      name: 'dataChangeRequests',
      ref: firestoreInstance.collection('dataChangeRequests').where('investor', '==', investorRef),
    });
    collections.investments = store.dispatch('bindRef', {
      name: 'investments',
      ref: removedAssets
        ? firestoreInstance
            .collection('investments')
            .where('deleted', '==', false)
            .where('investor', '==', investorRef)
            .where('asset', 'not-in', removedAssets)
            .orderBy('createdDateTime', 'desc')
        : firestoreInstance
            .collection('investments')
            .where('deleted', '==', false)
            .where('investor', '==', investorRef)
            .orderBy('createdDateTime', 'desc'),
    });
    collections.payments = store.dispatch('bindRef', {
      name: 'payments',
      ref: removedAssets
        ? firestoreInstance
            .collectionGroup('payments')
            .where('investor', '==', investorRef)
            .where('deleted', '==', false)
            .where('asset', 'not-in', removedAssets)
            .orderBy('updatedDateTime', 'desc')
        : firestoreInstance
            .collectionGroup('payments')
            .where('investor', '==', investorRef)
            .where('deleted', '==', false)
            .orderBy('updatedDateTime', 'desc'),
      options: {
        maxRefDepth: 0,
      },
    });
    collections.bids = store.dispatch('bindRef', {
      name: 'bids',
      ref: firestoreInstance
        .collectionGroup('bids')
        .where('investor', '==', investorRef)
        .where('deleted', '==', false)
        .orderBy('createdDateTime', 'desc'),
      options: {
        maxRefDepth: 0,
      },
    });
  }
};
