<template>
  <div id="app">
    <div v-if="(investorIsEnabled && !maintenanceMode) || isUserAdmin">
      <div
        v-if="isUserAdmin && (maintenanceMode || !investorIsEnabled)"
        class="alert alert--center alert--danger admin--alert"
      >
        {{
          maintenanceMode
            ? maintenanceMessage
              ? `The website is under maintenance and is showing the message: ${maintenanceMessage}`
              : 'The website is under maintenance'
            : 'Your user is disabled'
        }}
        but since you are admin you can still see the website.
      </div>
      <router-view :key="$route.fullPath" />
      <AuthModal v-if="authModal.isOpen && !isAuthPage" @closed="closeModal()" />
      <toast position="ne" />
    </div>
    <div v-else class="container text-center pt-5">
      <div class="mt-5 mx-auto">
        <div class="d-inline-block" role="alert">
          <font-awesome-icon class="mr-4" :icon="['fa', 'exclamation-triangle']" />
          <span>{{
            !maintenanceMode
              ? $t('common.accountDisabled')
              : maintenanceMessage
                ? maintenanceMessage
                : $t('common.websiteMaintenance')
          }}</span>
          <div v-if="investorDisabledMessage">
            <hr />
            <p class="mb-0">
              {{ investorDisabledMessage }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <RefreshModal v-if="shouldShowRefreshModal" @click-on-refresh="refreshPage" />
  </div>
</template>

<script lang="ts">
/* eslint no-console: "off" */
import { Vue, Component, Watch } from 'vue-property-decorator';
import { Action, Getter, State as ClassState } from 'vuex-class';
import { Toast } from 'vuex-toast';
import { MetaInfo } from 'vue-meta';
import { State as StateClass } from 'vuex-class/lib/bindings';
import { metaTitles, metaDescriptions, metaURL, metaLocale, metaImage, linkAlternate } from '@/helpers/meta';
import isAuthPageHelper from '@/helpers/isAuthPageHelper';
import { loadLanguageAsync } from '@/i18n';
import { Investor, UserStatus } from '@/store/models/user';
import { State } from '@/store/models';
import RefreshModal from '@/components/common/refresh-modal/RefreshModal.vue';
import { firebaseApp } from '@/firebase';
import initialiseValidators from './helpers/validators';

const isProductionEnvironment = process.env.NODE_ENV === 'production';

// @ts-expect-error - Missing types
const { projectId } = firebaseApp.options;

const isDevProject = projectId.includes('development');
const isProductionProject = projectId.includes('production');

initialiseValidators();

@Component({
  components: {
    Toast,
    AuthModal: (): unknown => import('@/components/common/auth/auth-modal/AuthModal.vue'),
    RefreshModal,
  },
})
export default class App extends Vue {
  metaInfo(): MetaInfo {
    const name = this.$greenTea.name;
    const { path } = this.$route;
    const { locale, availableLocales } = this.$i18n;
    // Appending the logo path (which contains a hash by Webpack) to the base URL to get the full URL
    const imageURL = this.$greenTea.website + require(`./assets/logos/${this.$greenTea.logoShare}`);
    return {
      title: name,
      htmlAttrs: {
        lang: locale,
      },
      // @ts-expect-error - Missing types
      link: [...linkAlternate(path, availableLocales)],
      // @ts-expect-error - missing types
      meta: [
        ...metaTitles(name, false),
        ...metaDescriptions(this.$t('meta.platform.description') as string),
        ...metaURL(path),
        ...metaLocale(locale, availableLocales),
        ...metaImage(imageURL),
      ],
    };
  }

  bundleVersion = '';

  // eslint-disable-next-line no-unused-vars
  @Action logOut!: (args: { redirect: string; idle: boolean }) => unknown;
  @Action closeModal!: () => unknown;

  @ClassState authModal!: State['authModal'];
  @ClassState user!: State['user'];
  @ClassState investor!: State['investor'];
  @ClassState admin!: State['admin'];
  @ClassState settings!: State['settings'];
  @StateClass misc!: State['misc'];

  @Getter isUserLoggedIn!: boolean;

  async mounted(): Promise<void> {
    if (process.env.NODE_ENV === 'production') {
      try {
        const newVersion = await this.fetchVersion();
        if (newVersion.startsWith('<!doctype html>')) {
          this.bundleVersion = 'development';
        } else {
          this.bundleVersion = newVersion;
        }
      } catch (error) {
        console.error(`There was an error fetching the version: ${(error as Error).toString()}`);
      }
    }
  }

  /**
   * Logging out when idle.
   */
  @Watch('isAppIdle')
  onNewAppIdle(newAppIdle: boolean, oldAppIddle: boolean): void {
    if (newAppIdle && !oldAppIddle && this.isUserLoggedIn) {
      this.logOut({ redirect: '/login', idle: newAppIdle });
    }
  }

  /**
   * Logging out when idle.
   */
  @Watch('admin', { deep: true, immediate: true })
  async onNewAdmin(): Promise<void> {
    // Omce the admin collection is there, trigger a language setting to include the assetType translations located in runtime configs
    if (this.admin) {
      const lang = this.$i18n?.locale;
      if (this.admin.assetTypes) {
        await loadLanguageAsync(lang, { assetTypes: this.admin.assetTypes });
      } else {
        await loadLanguageAsync(lang);
      }
    }
  }

  /**
   * Reloading the webapp when a new non-matching version is received.
   */
  @Watch('bundleVersion')
  @Watch('settings')
  onBundleVersionChange(): void {
    const versionInFirestore = isDevProject ? this.settings?.bloqifyDevVersion : this.settings?.bloqifyVersion;
    const bundleVersion = isProductionEnvironment ? this.bundleVersion : 'development';
    if (versionInFirestore && bundleVersion) {
      console.group('Bloqify ~');
      console.log(`Bundle version: ${bundleVersion}.`);
      console.log(`Version in Firestore: ${versionInFirestore}.`);
      console.groupEnd();
    }
  }

  /**
   * Sending some information to the intercom admins.
   */
  @Watch('user.name')
  onNewName(newName: string | undefined) {
    const intercom = this.$greenTea.intercom;
    if (newName && intercom && this.$intercom) {
      this.$intercom.update({
        // @ts-expect-error - Wrong types
        name: `${newName} ${(this.user as Investor)?.surname}`,
      });
    }
  }

  /**
   * Showing a modal to the user if the version in Firestore is different from the bundle version.
   */
  get shouldShowRefreshModal(): boolean {
    const versionInFirestore = isDevProject ? this.settings?.bloqifyDevVersion : this.settings?.bloqifyVersion;

    if (!isProductionEnvironment || !versionInFirestore || !this.bundleVersion) {
      return false;
    }

    // Prevent already refreshed versions from showing the modal.
    if (versionInFirestore === localStorage.getItem('versionInFirestore')) {
      return false;
    }

    // Temporary measure to avoid showing the modal in the production environment.
    // We need this to test the modal in a live env.
    // Meerdervoort has legacy naming pattern.
    if (isProductionProject || projectId === 'meerdervoort-bloqify') {
      return false;
    }

    return versionInFirestore !== this.bundleVersion;
  }

  /**
   * Investor status an admin can enter change via the Bloqadmin.
   */
  get investorIsEnabled(): boolean {
    if (!this.user) {
      return true;
    }

    return this.user.status !== UserStatus.Disabled;
  }

  /**
   * Optional disabled status message an
   * admin can enter in the Bloqadmin.
   */
  get investorDisabledMessage(): string | undefined {
    if (!this.user) {
      return undefined;
    }

    return this.user.statusMessage || undefined;
  }

  get maintenanceMode(): boolean {
    if (!this.admin) {
      return false;
    }

    return this.admin.maintenance;
  }

  get maintenanceMessage(): string {
    return this.admin?.maintenanceMessage || '';
  }

  get isUserAdmin(): boolean {
    if (!this.user) {
      return false;
    }

    return !!this.user.email && (this.user.email.endsWith('@bloqhouse.com') || this.user.email.endsWith('@bloq.house'));
  }

  get isAuthPage(): boolean {
    return isAuthPageHelper(this.$route);
  }

  refreshPage(): void {
    const versionInFirestore = isDevProject ? this.settings?.bloqifyDevVersion : this.settings?.bloqifyVersion;
    localStorage.setItem('versionInFirestore', versionInFirestore!);
    window.location.reload();
  }

  /**
   * Asumes CircleCI has created a file in /public with the version string.
   * This is used to compare the version from the file with the version in Firestore.
   */
  async fetchVersion(): Promise<string> {
    try {
      const response = await fetch('/version');
      if (!response.ok) {
        throw new Error('Error fetching version from file; network error.');
      }
      const version = await response.text();
      return version.trim();
    } catch (error) {
      console.error('Error fetching version from file:', error);
      throw error;
    }
  }
}
</script>

<style scoped>
.fade-leave-active {
  transition: opacity 1s;
}

.fade-leave-to {
  opacity: 0;
}

.admin--alert {
  z-index: 1000;
}
</style>
